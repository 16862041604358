<template>
    <searchBody>
        <search :show-create="true" class="searchbar" :label-width="80" :searchs="searchs" @search="onSearch" @reset="reset" @create="onCreate()"></search>
        <Card class="table_container" ref="table_container">
            <Table stripe class="table mt_10" :columns="dataColumn" :data="dataList" row-key="id">
                <template slot-scope="{ row }" slot="title">
                    <div class="relative flex align_center width_fit_content">
                        {{ row.title }}
                        <previewImage class="ml_10" et="" :thumWidth="30" :thumHeight="30" :src="row.icon"></previewImage>
                    </div>
                </template>
                <template slot-scope="{ row }" slot="goodNums">
                    {{ row.goodNums || 0 }}
                </template>
                <template slot-scope="{ row }" slot="path">
                    {{ !!row.pid ? '/pages/goods/index?mapping_value=' + row.id : '' }}
                </template>
                <template slot-scope="{ row }" slot="status">
                    <span :class="'now_state_' + row.status">{{ { 0: '否', 1: '是' }[row.status] }}</span>
                </template>
                <template slot-scope="{ row }" slot="manager">
                    <div class="table_manager flex flex_wrap align_center">
                        <Button v-if="'children' in row" type="success" size="small" ghost @click="onCreate(row, true)">添加子类</Button>
                        <Button type="primary" size="small" ghost @click="onCreate(row)">编辑</Button>
                        <Button type="error" size="small" ghost @click="onDelete(row)">删除</Button>
                    </div>
                </template>
            </Table>
        </Card>
        <div class="relative width_100_percent text_align_right">
            <Page size="small" :total="totalPageNum || 1" :page-size="1" show-elevator @on-change="currentChange" />
        </div>
        <mySpin :loading="ajaxLoading"></mySpin>

        <Modal :closable="false" v-model="showCreate" :title="title" width="500" :loading="ajaxLoading">
            <div v-if="!!formData.pid && !!!formData.id" class="form_item">
                <div class="form_label must_input">父类</div>
                {{ formData.ptitle }}
            </div>
            <div class="form_item">
                <div class="form_label must_input">分类名称</div>
                <Input v-model.trim="formData.title" :maxlength="15" show-word-limit></Input>
            </div>
            <div class="form_item">
                <div class="form_label must_input">是否显示</div>
                <RadioGroup v-model="formData.status" type="button" button-style="solid">
                    <Radio label="1">是</Radio>
                    <Radio label="0">否</Radio>
                </RadioGroup>
            </div>
            <div class="form_item">
                <div class="form_label align_self_start">缩略图</div>
                <div>
                    <uploadImage :width="80" :height="80" v-model="formData.icon"></uploadImage>
                    <div class="tip_txt">图片尺寸：192x158</div>
                </div>
            </div>
            <template v-if="!!formData.pid">
                <div class="form_item">
                    <div class="form_label">类型</div>
                    <Select v-model="formData.ser">
                        <Option v-for="info in goodsSerList" :key="info.id" :value="info.id + ''">{{ info.title }}</Option>
                    </Select>
                </div>
            </template>
            <div slot="footer" class="relative width_100_percent flex align_center justify_center">
                <Button type="primary" ghost @click="onCancelCreate">取消</Button>
                <Button class="ml_100" type="primary" :loading="ajaxLoading" @click="onConfirmCreate">确定</Button>
            </div>
        </Modal>
    </searchBody>
</template>
<script>
import pageMixins from '@/lib/pageMixins';
import tableSearch from '@/components/tableSearch';
import searchBody from '@/components/searchBody';
import previewImage from '@/components/previewImage';
import uploadImage from '@/components/uploadImage';
import { reqGoodsDelClass, reqGoodsListClass, reqGoodsSaveClass, reqGoodsSerList } from '@/lib/request/auth2';

export default {
    name: 'goodType',
    mixins: [pageMixins],
    components: {
        search: tableSearch,
        searchBody,
        previewImage,
        uploadImage,
    },
    data() {
        return {
            searchs: [
                // { id: hm.createId(), label: '发布日期', placeholder: '发布日期', type: 'daterange', bind: 'date', onChange: this.onChangeDateRange },
                { id: hm.createId(), label: '关键字', placeholder: '输入关键字', type: 'input', bind: 'title' },
            ],
            dataColumn: [
                { slot: 'title', title: '分类名称', minWidth: 120, tree: true },
                { slot: 'goodNums', title: '商品数量', minWidth: 100 },
                { slot: 'status', title: '是否显示', minWidth: 90 },
                { slot: 'path', title: 'path', minWidth: 200 },
                { slot: 'manager', title: '操作', minWidth: 120 },
            ],
            dataList: [],

            showCreate: false,
            title: '',
            formData: {
                pid: 0,
                ptitle: null,
                id: null,
                title: null,
                status: '1',
                icon: null,
                ser: '1',
            },
            goodsSerList: [],
        };
    },
    mounted() {
        this.getGoodsSerList();
        this.getList();
    },
    methods: {
        onChangeDateRange(evt) {
            this.params.startDate = evt[0] || null;
            this.params.endDate = evt[1] || null;
        },
        getGoodsSerList() {
            reqGoodsSerList().then(res => {
                let data = (res.data || {}).ser || [];
                this.goodsSerList = data;
            });
        },
        //获取列表数据
        getList() {
            console.log('getList', this.params);

            this.showAjaxLoading();
            reqGoodsListClass(this.params)
                .then(res => {
                    this.dataInjectDataList(res);
                    this.dataList.forEach(info => {
                        info._showChildren = true;
                    });
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
        },
        //删除
        onDelete(info = {}) {
            let { id, title } = info || {};
            this.confirmDialog(`确认删除【${title}】吗`).then(isOK => {
                if (isOK == 1) {
                    this.showAjaxLoading();
                    reqGoodsDelClass({ id })
                        .then(() => {
                            this.fadeAlert('删除成功');
                            this.getList();
                        })
                        .catch(res => {
                            this.hideAjaxLoading();
                            this.fadeWarning(res.errmsg);
                        });
                }
            });
        },

        //新增/编辑
        onCreate(row, isAddChild = false) {
            let { id, title, status, icon, ser } = row || {};

            this.formData.pid = 0;
            this.formData.ptitle = null;
            this.formData.id = null;
            this.formData.title = null;
            this.formData.status = '1';
            this.formData.icon = null;
            this.formData.ser = null;

            this.title = '添加分类';
            if (!!id && isAddChild) {
                this.title = '添加子类';
                this.formData.pid = id;
                this.formData.ptitle = title;
            } else if (!!id) {
                this.title = '编辑分类';
                this.formData.pid = row.pid || 0;
                this.formData.id = id;
                this.formData.title = title;
                this.formData.status = status;
                this.formData.icon = icon;
                this.formData.ser = (ser || '1') + '';
            }
            this.showCreate = true;
        },
        //取消 添加/修改分类
        onCancelCreate() {
            this.showCreate = false;
        },
        //确认添加/修改分类
        onConfirmCreate() {
            this.showAjaxLoading();
            let { pid, id, title, status, icon, ser } = this.formData || {};
            if (!hm.isNotEmptyString(title)) {
                this.hideAjaxLoading();
                this.fadeWarning('请输入分类名称');
                return;
            }

            reqGoodsSaveClass({ pid, id, title, status, icon, ser })
                .then(res => {
                    this.fadeAlert(res.errmsg);
                    this.onCancelCreate();
                    this.getList();
                })
                .catch(() => {
                    this.hideAjaxLoading();
                });
        },
    },
};
</script>
<style lang="less" scoped>
.form_label {
    width: 100px;
}
.table_container {
    & /deep/ .ivu-table-cell-slot {
        width: fit-content;
        display: inline-block;
    }
}
</style>
